import { ChangeEvent, Fragment, TextareaHTMLAttributes, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { useTranslation } from "react-i18next";
import { IAnswer } from "../../../types/answers/answerType";
import { ITest } from "../../../types/test_created/testTypes";
import { IQTests } from "../../../types/qTests/qTestsTypes";
import { IQuestion } from "../../../types/questions/questionsType";
import { IDataSubmit } from "../types";
import { LoadingData } from "../../Components";

import Markdown from "react-markdown";

interface IProps {
    index: number;
    dataSubmit: IDataSubmit;
    setDataSubmit: (data: IDataSubmit) => void;
    // setDataSubmit: React.Dispatch<React.SetStateAction<IDataSubmit>>;
    questionData: Partial<IQuestion>;
    testData: Partial<ITest>;
    qTestData: IQTests;
}

const QuestionTestComp = ({ index, dataSubmit, setDataSubmit, questionData, testData, qTestData }: IProps) => {
    // hooks
    const { t } = useTranslation();

    // states
    const [answers, setAnswers] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState<string | null>(null);

    // functions
    useEffect(() => {
        if (answers.length === 0) {
            const result = questionData.answers?.sort(() => Math.random() - 0.5);
            setAnswers(result as []);
        }
    }, [index, dataSubmit, setDataSubmit, questionData, testData]);

    useEffect(() => {
        setLoading(false);
    }, [answers]);

    const handleAnswer = (e: { target: { name: string; value: string } }, correct: boolean) => {
        const { name, value } = e.target;

        setDataSubmit({
            ...dataSubmit,
            id_question: questionData.id,
            correct: correct,
            [name]: parseInt(value)
        });
    };

    if (loading) return <LoadingData />;

    const handleImageClick = (src: string) => {
        setModalImage(src);
        setModalOpen(true);
    };

    function renderAnswers(element: IAnswer, key: number) {
        return (
            <Fragment key={key}>
                <div
                    className={`${
                        (qTestData.id_answer || dataSubmit.saved) &&
                        questionData.type !== "open" &&
                        "pointer-events-none"
                    }`}
                >
                    {questionData.type === "open" ? (
                        <>
                            <textarea
                                id="ctnTextarea"
                                rows={5}
                                name="id_answer"
                                className="form-textarea mb-5"
                                placeholder={t("your_answer")}
                                onBlur={(e) =>
                                    handleAnswer(
                                        {
                                            target: {
                                                name: e.target.name,
                                                value: e.target.value === "" ? "0" : element.id.toString()
                                            }
                                        },
                                        element.correct
                                    )
                                }
                                required
                            ></textarea>
                        </>
                    ) : (
                        <>
                            <label className="flex mt-4 cursor-pointer">
                                <input
                                    type="radio"
                                    name="id_answer"
                                    className={`${
                                        dataSubmit.saved
                                            ? element.correct
                                                ? "form-radio text-success"
                                                : "form-radio text-danger"
                                            : "form-radio"
                                    } peer`}
                                    value={element.id}
                                    defaultChecked={element.id === qTestData.id_answer ? true : false}
                                    onChange={(e) => handleAnswer(e, element.correct)}
                                />
                                <span
                                    className={`${
                                        dataSubmit.saved ? (element.correct ? "text-success" : "text-danger") : "none"
                                    }`}
                                >
                                    <Markdown className={"whitespace-pre-wrap"}>{element.description}</Markdown>
                                </span>
                            </label>
                        </>
                    )}
                </div>

                <div>
                    {dataSubmit.saved && (
                        <Markdown className={"whitespace-pre-wrap"}>{element.tutor_explanation}</Markdown>
                    )}
                </div>
            </Fragment>
        );
    }

    // render
    return (
        <div
            onClick={() => {
                if (modalOpen) setModalOpen(false);
            }}
        >
            <div className="flex-1 items-center justify-between flex-wrap gap-4">
                <div className="flex-col">
                    <div className="">
                        <div className="w-full-auto p-5 h-auto">
                            <div className="flex w-full justify-center items-center mb-3">
                                {dataSubmit.saved &&
                                    Array.isArray(questionData.video_questions) &&
                                    questionData.video_questions?.length > 0 && (
                                        <div>
                                            <iframe
                                                src={`https://www.youtube.com/embed/${questionData.video_questions[0].url}?si=b5uC6bXR7NR5a8uD`}
                                                title="YouTube video explanation"
                                                className="w-[560px] h-[315px] mobile:w-full mobile:h-full"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    )}
                            </div>
                            <div>
                                <Markdown
                                    className={"whitespace-pre-wrap rounded-md p-2"}
                                    components={{
                                        img: ({ src, alt }) => (
                                            <img
                                                src={src || ""}
                                                alt={alt || ""}
                                                className="cursor-pointer"
                                                onClick={() => handleImageClick(src || "")}
                                            />
                                        )
                                    }}
                                >
                                    {questionData.description}
                                </Markdown>
                            </div>
                            <div>{answers.length > 0 && <div className="mt-2">{answers.map(renderAnswers)}</div>}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {modalOpen && modalImage && (
                <div className="fixed top-6 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative">
                        <img src={modalImage} alt="Modal Content" className="w-[85%] h-[85%] mobile:w-full" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionTestComp;
